import Particles from "react-tsparticles";
import type { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import "./App.css";
import particlesOptions from "./particles.json";
import { ISourceOptions } from "tsparticles-engine";

function App() {
  const particlesInit = async (main: Engine) => {
    console.log(main);
    await loadFull(main);
  };

  return (
    <div className="App">
      <Particles
        options={particlesOptions as ISourceOptions}
        init={particlesInit}
      />
      <header className="App-header"></header>
    </div>
  );
}

export default App;
